import Amenities from '../amenities/amenities';
import HotelDescription from '../property/hotel-description/HotelDescription';
import type { GetHotelHomePageQuery } from '../../generated/types';

type HotelQuery = NonNullable<GetHotelHomePageQuery['hotel']>;

export type DescriptionAmenitiesProps = {
  amenities: NonNullable<HotelQuery>['amenities'] | undefined;
  brandCode: NonNullable<HotelQuery>['brandCode'] | undefined;
  headline: NonNullable<HotelQuery['facilityOverview']>['headline'];
  shortDesc: NonNullable<HotelQuery['facilityOverview']>['shortDesc'];
};

const DescriptionAmenities = ({
  amenities,
  brandCode,
  ...descriptionProps
}: DescriptionAmenitiesProps) => (
  <div className="container flex flex-col py-8 lg:flex-row lg:py-10">
    <HotelDescription {...descriptionProps} />
    <Amenities amenities={amenities} brandCode={brandCode} />
  </div>
);

export default DescriptionAmenities;
