import { BrandLink } from '../brand-buttons/brand-buttons';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import type { GetHotelHomePageQuery } from '../../generated/types';
import { generateUrl } from '../../helpers/property-utils';
import { useGlobalConfig } from '../../hooks/use-global-config';
import { useMetrics } from '../../hooks/use-metrics';
import { IconGrid } from '../icon-grid';

type TAmenitiesProps = {
  amenities?: NonNullable<GetHotelHomePageQuery['hotel']>['amenities'];
  brandCode?: string;
};

const Amenities = ({ amenities, brandCode }: TAmenitiesProps) => {
  const metrics = useMetrics();
  const { t } = useTranslation('accessibility-grid');
  const { relativeUrl } = generateUrl(useRouter(), 'hotel-info');
  const { isTailored } = useGlobalConfig();

  if (!amenities || !amenities.length) return null;

  return (
    <div
      className={cx('w-full', {
        'lg:ps-8': !isTailored,
        'flex items-center': isTailored,
      })}
    >
      <IconGrid brandCode={brandCode} gridItems={amenities} title={t('title')} />
      {!isTailored ? (
        <div className="flex justify-center lg:justify-start">
          <BrandLink
            url={relativeUrl}
            onClick={() => {
              if (metrics) metrics.trackAccessibleAmenities();
            }}
            label={t('buttonTitle')}
            variant="outline"
          />
        </div>
      ) : null}
    </div>
  );
};

export default Amenities;
export { Amenities };
